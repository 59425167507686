.wrapper {
    position: relative;
}

.mapCanvas {
    width: 100%;
    height: 100%;
    position: absolute;
}

.gatewayCard {
    position: absolute;
    width: 200px;
    z-index:1000;
}

.iconPlusText {
    display: 'flex';
    align-items: 'center';
    flex-wrap: 'wrap';
}
