.Wrapper {
  padding: 0.5rem;
}

.ListContainer {
  margin-right: 0.5rem;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  border-color: lightgray;
  background-color: #ffffff;
  height: 100%;
  width: 90%;
  overflow-y: scroll;
}

.EmptyListContainer {
  margin-right: 0.5rem;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  border-color: lightgray;
  background-color: #ffffff;
  height: 100%;
  width: 90%;
}

.LocalFileContainer {
  background-color: white;
  height: 100%;
  border: 1px solid lightgray;
  border-radius: 2px;
}
