.MapColumn {
  height: 70%;
}

.ChartsColumn {
  height: 30%;

  .ant-card-head {
    height: 15%;
  }

  .ant-card-head-wrapper {
    height: 100%;
  }

  .ant-card-body {
    height: 75%; // 100% - ant-card-head height
    padding: 0.75rem;
  }
}

