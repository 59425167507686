body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.Overview {
    padding: 8px;

    .ActionColumn {
        height: 5%;

        button {
            max-height: 100%;
        }
    }

    .DetailsColumn {
      height: 95%;
    }
}

.FullHeight{
  height: 100%;
}

.DetailsRow {
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  height: 100%;
}

tr .MuiTableCell-sizeSmall{
  padding: 6px 3px 6px 3px;
}

