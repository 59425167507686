.OverviewOrDetails {
  padding: 8px;

  .ActionColumn {
    height: 5%;

    button {
      max-height: 100%;
    }
  }

  .MainColumn {
    height: 95%;
  }

}