.edgeCanvas {
    height: 100%;
    width: 100%;
    position: absolute;
}

.card {
    margin-top: 0.5rem;
    width: 100%;
}

.wrapper {
    padding: 0.5rem;
    position: relative;
}

.iconPlusText {
    display: 'flex';
    align-items: 'center';
    flex-wrap: 'wrap';
}
