.site-page-header-ghost-wrapper {
    padding: 1px;
    background-color: rgb(63, 81, 181);
}

.site-page-header-ghost-wrapper > div {
    padding: 0.7rem;
}
.description-item .ant-descriptions-item-label {
    color: black; //navy
}